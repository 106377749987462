import { GetOrderList } from "@/api/GoodsApi";
import { LogisticStatus, OrderStatus, ProductSourceType, sortDirection, OrderType } from "@/common/enum";
import CategoryService from "@/services/Goods/CategoryService";
import { reactive, ref } from "vue";
import { FormatterDateTime } from "@/common/formatter";
import TableSortClick from "@/views/Common/SortTable";
import { ScrollToTop } from "@/common/utils";
import router from "@/router";
import ShopService from "@/services/Shop/ShopService";
import ChannelService from "@/services/Channel/ChannelService";
const { setCurrentSort, headerSortClick } = TableSortClick();
setCurrentSort({
    prop: "ctime",
});
export default function OrderQuery() {
    const showDownloadDialog = ref(false);
    const queryId = router.currentRoute.value.query.id && router.currentRoute.value.query.id.length > 0 ? router.currentRoute.value.query.id : '';
    const queryArgs = reactive({
        page: 1,
        count: 20,
        qid: '',
        productNo: '',
        id: queryId,
        couponName: '',
        startCtime: queryId.length ? '' : FormatterDateTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0), "yyyy-MM-dd hh:mm:ss"),
        endCtime: queryId.length ? '' : FormatterDateTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59), "yyyy-MM-dd hh:mm:ss"),
        startRefundTime: '',
        endRefundTime: '',
        catId: '',
        supplierType: ProductSourceType.All,
        orderStatus: OrderStatus.All,
        cargoStatus: LogisticStatus.All,
        shopId: ShopService.GetCurrentShop().id,
        refFrom: '',
        orderType: 1,
        sortDirection: 0,
        isDiscount: '',
    });
    const shopList = ShopService.GetAllShop();
    const channelList = ChannelService.GetChannelList();
    const allTypes = reactive(new Array());
    allTypes.splice(0, allTypes.length);
    const cateResult = CategoryService.GetAllCatagory();
    cateResult.forEach((item) => {
        allTypes.push(item);
    });
    const onCateChanged = (val) => {
        queryArgs.catId = val.id;
    };
    const datas = reactive(new Array());
    const totalCount = ref(0);
    const loading = ref(false);
    const queryData = () => {
        GetOrderList(queryArgs).then((res) => {
            loading.value = true;
            loading.value = false;
            if (res.isSuccess) {
                datas.splice(0, datas.length);
                const list = res.resultData.list;
                list.forEach(item => {
                    datas.push(item);
                });
                totalCount.value = res.resultData.total;
            }
            ScrollToTop();
        });
    };
    const handleCurrentChange = (val) => {
        queryArgs.page = val;
        queryData();
    };
    const handleSizeChange = (val) => {
        queryArgs.count = val;
        queryData();
    };
    const changeShopName = (val) => {
        // val ? getChannelList(val) : channelList.splice(0, shopList.length);
        queryArgs.refFrom = '';
    };
    const changeTime = (val, type) => {
        if (type == 1) {
            queryArgs.startCtime = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || '';
            queryArgs.endCtime = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || '';
        }
        else {
            queryArgs.startRefundTime = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || '';
            queryArgs.endRefundTime = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || '';
        }
    };
    const expDeliver = () => {
        showDownloadDialog.value = true;
    };
    const headerClick = async (column) => {
        if (await headerSortClick(column)) {
            queryArgs.orderType = column.prop == 'ctime' ? OrderType.PurchaseTime : OrderType.SendTime;
            queryArgs.sortDirection = sortDirection[column.order] * 1;
            queryArgs.page = 1;
            queryData();
        }
    };
    return {
        showDownloadDialog,
        queryArgs,
        allTypes,
        onCateChanged,
        queryData,
        datas,
        totalCount,
        loading,
        handleCurrentChange,
        handleSizeChange,
        shopList,
        channelList,
        changeShopName,
        expDeliver,
        changeTime,
        headerClick
    };
}
